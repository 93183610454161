import type { BalanceData } from './api/balance/useFetchBalance'

export const useBalances = () => {
  const { data: balances } = useQueryBalance.all()

  const createHasUnlimitedBalanceComputed = (key: keyof BalanceData['balance']) => computed(() =>
    balances.value?.[key] === -1,
  )

  const createBalanceComputed = (key: keyof BalanceData['balance']) => computed(() =>
    balances.value?.[key] ?? 0,
  )

  return {
    balances,

    /**
     * the amount of signatures **not** included in the subscription
     */
    signatureBalance: createBalanceComputed('signature'),
    hasUnlimitedSignatures: createHasUnlimitedBalanceComputed('signature'),

    /**
     * the amount of signatures included in the subscription
     */
    signatureSubscriptionBalance: createBalanceComputed('signature-subscription'),
    hasUnlimitedSubscriptionSignatures: createHasUnlimitedBalanceComputed('signature-subscription'),

    hasUnlimitedFreeContracts: createHasUnlimitedBalanceComputed('free-contract'),
    freeContractBalance: createBalanceComputed('free-contract'),
  }
}
